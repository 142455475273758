import Vue from 'vue'
import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getAccessToken()
        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          const accessTokenAdmin = this.getAdminToken()

          if (accessToken !== accessTokenAdmin) {
          // eslint-disable-next-line no-param-reassign
            config.headers.Admin = `${accessTokenAdmin}`
          }
        }

        if (config.method === 'get' || (config.method === 'post' && (config.url.includes('filter') || config.url.includes('get-applicants') || config.url.includes('promotion-report') || config.url.includes('get-active-peers')))) {
          // AQUI esta el problema: $router es undefined, de alguna forma se tendria que sacar el currentRoute.name y deberia funcionar todo (duplicar en perPage)
          const page = JSON.parse(localStorage.getItem('pagination')) ?? {}
          if (page[localStorage.getItem('currentRoute')]) {
            // eslint-disable-next-line no-param-reassign
            config.params = { ...config.params, page: page[localStorage.getItem('currentRoute')] }
          }

          const perPage = JSON.parse(localStorage.getItem('per_page')) ?? {}
          if (perPage[localStorage.getItem('currentRoute')]) {
            // eslint-disable-next-line no-param-reassign
            config.params = { ...config.params, per_page: perPage[localStorage.getItem('currentRoute')] }
          }
        }

        const params = new URLSearchParams(window.location.search)
        if (config.url.includes('filter') && params.has('prefilterUser') && config.method === 'post') {
          const data = JSON.parse(JSON.stringify(config.data))
          data.researcher = { id: params.get('prefilterUser') }
          // eslint-disable-next-line no-param-reassign
          config.data = data
        }

        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && (response.status === 401)) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false
              // Update accessToken in localStorage
              const params = new URLSearchParams(window.location.search)
              if (r.data && r.data.authorisation && r.data.authorisation.token) {
                this.setToken(r.data.authorisation.token)
                this.setRefreshToken(r.data.refreshToken)
                this.onAccessTokenFetched(r.data.accessToken)
              } else if (params.has('actAs')) {
                this.getNewAccessToken()
              } else if (window.location.href.includes('/intranet')) {
                localStorage.removeItem('accessTokenAdmin')
                window.location.href = '/intranet'
              } else {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')
                window.location.href = '/login'
              }
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        if (response && (response.status === 422)) {
          if (response.data && response.data.errors) {
            const errors = Object.keys(response.data.errors).map(item => response.data.errors[item].join('<br>'))
            Vue.swal('Error - Required information missing', errors.join('<br>'), 'error')
          } else {
            Vue.swal('Error detected', response.data.message, 'error')
          }
        }
        if (response && (response.status === 403)) {
          // TODO: Redirect user to the previous page
          Vue.swal('Unauthorized action', '', 'error')
          // eslint-disable-next-line no-restricted-globals
          history.back()
        }
        if (response && (response.status === 500)) {
          if (response.data.exception !== 'PHPOpenSourceSaver\\JWTAuth\\Exceptions\\TokenInvalidException') {
            Vue.swal('Something went wrong', 'Your error has been successfully logged. <br>If you wish to give us more information, you can do so from the red button with the text "To report an error, click here" located at the top of the screen.', 'error')
          }
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getAdminToken() {
    return localStorage.getItem(this.jwtConfig.storageAdminTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  getAccessToken() {
    // Get token from localStorage
    let accessToken = this.getToken()
    const accessTokenAdmin = this.getAdminToken()
    const params = new URLSearchParams(window.location.search)

    if (params.has('actAs')) {
      const fakeUsersTokens = JSON.parse(localStorage.getItem('fakeUsersToken'))
      const user = fakeUsersTokens.find(x => x.id === parseInt(params.get('actAs'), 0))

      if (user !== undefined) {
        accessToken = user.token
      } else {
        window.location.href = this.$router.currentRoute.path
      }
    } else if (accessTokenAdmin && window.location.href.includes('/intranet')) {
      accessToken = accessTokenAdmin
    }

    return accessToken
  }

  setToken(value) {
    const params = new URLSearchParams(window.location.search)

    if (params.has('actAs')) {
      const fakeUsersTokens = JSON.parse(localStorage.getItem('fakeUsersToken'))
      const user = fakeUsersTokens.findIndex(x => x.id === parseInt(params.get('actAs'), 0))

      if (user !== undefined) {
        fakeUsersTokens[user].token = value
        localStorage.setItem('fakeUsersToken', JSON.stringify(fakeUsersTokens))
      }
    } else if (window.location.href.includes('/intranet')) {
      localStorage.setItem(this.jwtConfig.storageAdminTokenKeyName, value)
    } else {
      localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  backLogin(...args) {
    return this.axiosIns.post(this.jwtConfig.backLoginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  async refreshToken() {
    const resp = await this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getAccessToken(),
    }).catch(async () => {
      const params = new URLSearchParams(window.location.search)
      let token = ''

      if (params.has('actAs')) {
        const axiosIns = axios.create({
          headers: { Authorization: `Bearer ${this.getAdminToken()}` },
        })

        const fakeUsersTokens = JSON.parse(localStorage.getItem('fakeUsersToken'))
        const index = fakeUsersTokens.findIndex(x => x.token === this.getAccessToken())
        token = await axiosIns.get(`${Vue.prototype.$identityUrl}/user/fake-user/${fakeUsersTokens[index].id}`)
        fakeUsersTokens[index].token = token.data.authorisation.token

        localStorage.setItem('fakeUsersToken', JSON.stringify(fakeUsersTokens))
      } else if (window.location.href.includes('/intranet')) {
        localStorage.removeItem('accessTokenAdmin')
        window.location.href = '/intranet'
      } else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        window.location.href = '/login'
      }

      return token
    })

    return resp
  }

  async getNewAccessToken() {
    const fakeUsersTokens = JSON.parse(localStorage.getItem('fakeUsersToken'))
    const index = fakeUsersTokens.findIndex(x => x.token === this.getAccessToken())

    const axiosIns = axios.create({
      headers: { Authorization: `Bearer ${this.getAdminToken()}` },
    })

    const resp = await axiosIns.get(`${Vue.prototype.$identityUrl}/user/fake-user/${fakeUsersTokens[index].id}`)
    fakeUsersTokens[index].token = resp.data.authorisation.token

    localStorage.setItem('fakeUsersToken', JSON.stringify(fakeUsersTokens))
    window.location.reload()
  }
}
