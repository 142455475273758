import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {},
    items: [],
    itemsTotal: 0,
    activeMemoir: {},
    years: [],
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
    item: state => state.item,
    activeMemoir: state => state.activeMemoir,
    years: state => state.years,
  },
  mutations: {
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ACTIVE_MEMOIR(state, activeMemoir) {
      state.activeMemoir = activeMemoir
    },
    SAVE_YEARS(state, years) {
      state.years = years
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    CLEAR_ITEM(state) {
      state.item = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoirs`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoirs/filter`, filters)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchId({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoirs/${id}`)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/memoirs/${data.id}`, data.data)
        Vue.prototype.$saveFiles([data.data.file])
        Vue.prototype.$saveFiles([data.data.image])
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoirs`, data)
        Vue.prototype.$saveFiles([data.file])
        Vue.prototype.$saveFiles([data.image])
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getActiveMemoir({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoirs/getActiveMemoir`)
        commit('SAVE_ACTIVE_MEMOIR', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getYears({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoirs/getYears`)
        commit('SAVE_YEARS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanType({ commit }) {
      commit('CLEAR_ITEM')
    },
    // export({ commit }, filters) {
    //   try {
    //     Vue.prototype.$export({
    //       url: `${Vue.prototype.$groupUrl}/memoirs/export`,
    //       filters,
    //       name: 'unpaid_leaves',
    //     })
    //   } catch (e) {
    //     console.log(e)
    //     commit('FETCH_FAILED')
    //   }
    // },
  },
}
