import Vue from 'vue'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    currentQuery: null,
    moderators: {},
    aspirants: {},
    fakeUsers: [],
    lastAccess: [],
    contacts: [],
    user: {},
    users: [],
    usersTotal: 0,
    evaluatorsList: [],
    evaluatorsListTotal: 0,
    evaluationsList: [],
    evaluationsListTotal: 0,
    evaluators: [],
    administrators: [],
    canSignUsers: [],
    hasLegal: false,
    dataEvaluationsView: {},
    // Dashboard
    calendar: [],
    alarms: [],
    tasks: [],
    grants: [],
    traces: [],
    timeline: [],
    // Administrative data
    salaryHistories: [],
    supplements: [],
    unpaidLeaves: [],
    sabbaticals: [],
    sharedLabourHazards: [],
    dedications: [],
    residencies: [],
    temporaryLeaves: [],
    otherContracts: [],
    otherAgreements: [],
    hostHistories: [],
    icreaContracts: [],
    basicData: [],
    auxUsers: [],
    scientificStats: [],
    scientificPersonalInfo: [],
    scientificPublications: [],
    scientificGrants: [],
    scientificConferences: [],
    scientificCourses: [],
    scientificPhds: [],
    scientificManagerials: [],
    scientificDisseminations: [],
    scientificStaysResearch: [],
    scientificSabbaticals: [],
    scientificPatents: [],
    peers: [],
    evaluatorsTotal: 0,
    evaluatorHostHistories: [],
    promotionHistories: [],
    userCVs: [],
    totalPeers: 0,
  },
  getters: {
    currentQuery: state => state.currentQuery,
    moderators: state => state.moderators,
    aspirants: state => state.aspirants,
    fakeUsers: state => state.fakeUsers,
    lastAccess: state => state.lastAccess,
    contacts: state => state.contacts,
    user: state => state.user,
    users: state => state.users,
    evaluatorsList: state => state.evaluatorsList,
    evaluatorsListTotal: state => state.evaluatorsListTotal,
    evaluationsList: state => state.evaluationsList,
    evaluationsListTotal: state => state.evaluationsListTotal,
    evaluators: state => state.evaluators,
    administrators: state => state.administrators,
    hasLegal: state => state.hasLegal,
    usersTotal: state => state.usersTotal,
    calendar: state => state.calendar,
    alarms: state => state.alarms,
    tasks: state => state.tasks,
    grants: state => state.grants,
    traces: state => state.traces,
    timeline: state => state.timeline,
    salaryHistories: state => state.salaryHistories,
    supplements: state => state.supplements,
    unpaidLeaves: state => state.unpaidLeaves,
    sabbaticals: state => state.sabbaticals,
    sharedLabourHazards: state => state.sharedLabourHazards,
    basicData: state => state.basicData,
    auxUsers: state => state.auxUsers,
    dedications: state => state.dedications,
    residencies: state => state.residencies,
    temporaryLeaves: state => state.temporaryLeaves,
    otherContracts: state => state.otherContracts,
    icreaContracts: state => state.icreaContracts,
    otherAgreements: state => state.otherAgreements,
    hostHistories: state => state.hostHistories,
    scientificStats: state => state.scientificStats,
    scientificPersonalInfo: state => state.scientificPersonalInfo,
    scientificPublications: state => state.scientificPublications,
    scientificGrants: state => state.scientificGrants,
    scientificConferences: state => state.scientificConferences,
    scientificCourses: state => state.scientificCourses, // Courses
    scientificPhds: state => state.scientificPhds, // PHDs
    scientificManagerials: state => state.scientificManagerials, // Managerials
    scientificDisseminations: state => state.scientificDisseminations, // Disseminations
    scientificStaysResearch: state => state.scientificStaysResearch, // Stays of research
    scientificSabbaticals: state => state.scientificSabbaticals, // Sabbaticals
    scientificPatents: state => state.scientificPatents, // Patents
    peers: state => state.peers,
    evaluatorsTotal: state => state.evaluatorsTotal,
    evaluatorHostHistories: state => state.evaluatorHostHistories,
    promotionHistories: state => state.promotionHistories,
    userCVs: state => state.userCVs,
    canSignUsers: state => state.canSignUsers,
    dataEvaluationsView: state => state.dataEvaluationsView,
    totalPeers: state => state.totalPeers,
  },
  mutations: {
    SAVE_CURRENT_QUERY(state, currentQuery) {
      state.currentQuery = currentQuery
    },
    SAVE_HAS_LEGAL(state, hasLegal) {
      state.hasLegal = hasLegal
    },
    // <-- DASHBOARD -->
    SAVE_CALENDAR(state, calendar) {
      state.calendar = calendar
    },
    SAVE_ALARMS(state, alarms) {
      state.alarms = alarms
    },
    SAVE_TASKS(state, tasks) {
      state.tasks = tasks
    },
    SAVE_GRANTS(state, grants) {
      state.grants = grants
    },
    SAVE_TRACES(state, traces) {
      state.traces = traces
    },
    SAVE_TIMELINE(state, timeline) {
      state.timeline = timeline
    },
    SAVE_CAN_SIGN_USERS(state, canSignUsers) {
      state.canSignUsers = canSignUsers
    },
    // <!-- DASHBOARD -->
    // <-- ADMINISTRATIVE DATA -->
    SAVE_PROMOTION_HISTORIES(state, promotionHistories) {
      state.promotionHistories = promotionHistories
    },
    SAVE_SALARY_HISTORIES(state, salaryHistories) {
      state.salaryHistories = salaryHistories
    },
    SAVE_SUPPLEMENTS(state, supplements) {
      state.supplements = supplements
    },
    SAVE_UNPAID_LEAVES(state, unpaidLeaves) {
      state.unpaidLeaves = unpaidLeaves
    },
    SAVE_SABBATICALS(state, sabbaticals) {
      state.sabbaticals = sabbaticals
    },
    SAVE_SHARED_LABOUR_HAZARDS(state, sharedLabourHazards) {
      state.sharedLabourHazards = sharedLabourHazards
    },
    SAVE_DEDICATIONS(state, dedications) {
      state.dedications = dedications
    },
    SAVE_RESIDENCIES(state, residencies) {
      state.residencies = residencies
    },
    SAVE_TEMPORARY_LEAVES(state, temporaryLeaves) {
      state.temporaryLeaves = temporaryLeaves
    },
    SAVE_OTHER_CONTRACTS(state, otherContracts) {
      state.otherContracts = otherContracts
    },
    SAVE_HOST_HISTORIES(state, hostHistories) {
      state.hostHistories = hostHistories
    },
    SAVE_EVALUATOR_HOST_HISTORIES(state, evaluatorHostHistories) {
      state.evaluatorHostHistories = evaluatorHostHistories
    },
    SAVE_ICREA_CONTRACTS(state, icreaContracts) {
      state.icreaContracts = icreaContracts
    },
    SAVE_BASIC_DATA(state, basicData) {
      state.basicData = basicData
    },
    SAVE_OTHER_AGREEMENTS(state, otherAgreements) {
      state.otherAgreements = otherAgreements
    },
    // <!-- ADMINISTRATIVE DATA -->
    SAVE_EVALUATORS(state, evaluators) {
      state.evaluators = evaluators
    },
    SAVE_EVALUATORS_TOTAL(state, evaluatorsTotal) {
      state.evaluatorsTotal = evaluatorsTotal
    },
    SAVE_MODERATORS(state, moderators) {
      state.moderators = moderators
    },
    SAVE_ADMINISTRATORS(state, administrators) {
      state.administrators = administrators
    },
    SAVE_CONTACTS(state, contacts) {
      state.contacts = contacts
    },
    SAVE_ASPIRANTS(state, aspirants) {
      state.aspirants = aspirants
    },
    SAVE_FAKE_USERS(state, fakeUsers) {
      state.fakeUsers = fakeUsers
    },
    SAVE_LAST_ACCESS(state, lastAccess) {
      state.lastAccess = lastAccess
    },
    SAVE_USER(state, user) {
      state.user = user
    },
    SAVE_USERS(state, users) {
      state.users = users
    },
    SAVE_USERS_TOTAL(state, usersTotal) {
      state.usersTotal = usersTotal
    },
    SAVE_EVALUATORS_LIST(state, evaluatorsList) {
      state.evaluatorsList = evaluatorsList
    },
    SAVE_EVALUATORS_LIST_TOTAL(state, evaluatorsListTotal) {
      state.evaluatorsListTotal = evaluatorsListTotal
    },
    SAVE_EVALUATIONS_LIST(state, evaluationsList) {
      state.evaluationsList = evaluationsList
    },
    SAVE_EVALUATIONS_LIST_TOTAL(state, evaluationsListTotal) {
      state.evaluationsListTotal = evaluationsListTotal
    },
    // <-- SCIENTIFIC PRODUCTION REPORT -->
    SAVE_SCIENTIFIC_STATS(state, scientificStats) {
      state.scientificStats = scientificStats
    },
    SAVE_SCIENTIFIC_PERSONAL_INFO(state, scientificPersonalInfo) {
      state.scientificPersonalInfo = scientificPersonalInfo
    },
    SAVE_SCIENTIFIC_PUBLICATIONS(state, scientificPublications) {
      state.scientificPublications = scientificPublications
    },
    SAVE_SCIENTIFIC_GRANTS(state, scientificGrants) {
      state.scientificGrants = scientificGrants
    },
    SAVE_SCIENTIFIC_CONFERENCES(state, scientificConferences) {
      state.scientificConferences = scientificConferences
    },
    SAVE_SCIENTIFIC_COURSES(state, scientificCourses) {
      state.scientificCourses = scientificCourses
    },
    SAVE_SCIENTIFIC_MANAGERIALS(state, scientificManagerials) {
      state.scientificManagerials = scientificManagerials
    },
    SAVE_SCIENTIFIC_PHDS(state, scientificPhds) {
      state.scientificPhds = scientificPhds
    },
    SAVE_SCIENTIFIC_DISSEMINATIONS(state, scientificDisseminations) {
      state.scientificDisseminations = scientificDisseminations
    },
    SAVE_SCIENTIFIC_STAYS_RESEARCH(state, scientificStaysResearch) {
      state.scientificStaysResearch = scientificStaysResearch
    },
    SAVE_SCIENTIFIC_SABBATICALS(state, scientificSabbaticals) {
      state.scientificSabbaticals = scientificSabbaticals
    },
    SAVE_SCIENTIFIC_PATENTS(state, scientificPatents) {
      state.scientificPatents = scientificPatents
    },
    SAVE_DATA_EVALUATIONS_VIEW(state, dataEvaluationsView) {
      state.dataEvaluationsView = dataEvaluationsView
    },
    // <!-- SCIENTIFIC PRODUCTION REPORT -->
    CLEAR(state) {
      state.moderators = {}
    },
    CLEAR_ASPIRANTS(state) {
      state.aspirants = {}
    },
    CLEAR_USERS(state) {
      state.users = {}
    },
    CLEAR_USER(state) {
      state.user = {
        direct_permissions: [],
        main_ercs: {
          level1: null,
          level2: null,
          level3: null,
        },
        additional_ercs: [],
        keywords: [],
      }
    },
    SAVE_AUX_USERS(state, auxUsers) {
      state.auxUsers = auxUsers
    },
    CLEAR_AUX_USERS(state) {
      state.auxUsers = []
    },
    CLEAR_EVALUATORS(state) {
      state.evaluators = []
    },
    SAVE_PEERS(state, peers) {
      state.peers = peers
    },
    SAVE_PEERS_TOTAL(state, peers) {
      state.totalPeers = peers
    },
    SAVE_CVS(state, userCVs) {
      state.userCVs = userCVs
    },
  },
  actions: {
    async fetchEvaluators({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/user/get-evaluators?name=${data.name}&convo=${data.convo}`)
        commit('SAVE_EVALUATORS', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async filterEvaluators({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/user/filter-evaluators`, data)
        commit('SAVE_EVALUATORS', resp.data.data)
        commit('SAVE_EVALUATORS_TOTAL', resp.data.total)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchEvaluatorsNoConvo({ commit }, data) {
      try {
        if (data.name.length > 2) {
          const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/user/get-evaluators?name=${data.name}`)
          commit('SAVE_EVALUATORS', resp.data.data)
        } else {
          commit('CLEAR_EVALUATORS')
        }
      } catch (e) {
        console.log(e)
      }
    },
    async fetchAdministrators({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/user/get-administrators?name=${data.name}`)
        commit('SAVE_ADMINISTRATORS', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchUsers({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users?min=true`)
        commit('SAVE_USERS_TOTAL', resp.data.total)
        commit('SAVE_USERS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_USERS')
      }
    },
    async fetchAuxUsers({ commit, state }, filters) {
      try {
        if (state.currentQuery) {
          state.currentQuery.cancel('Request canceled by user')
        }

        commit('SAVE_CURRENT_QUERY', axios.CancelToken.source())

        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/filter-management?min=true`, filters, { cancelToken: state.currentQuery.token })
        commit('SAVE_AUX_USERS', resp.data.data.data)
      } catch (e) {
        console.log(e)
        commit('CLEAR_AUX_USERS')
      }
    },
    async fetchCanSignUsers({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users/getCanSignUsers`)
        commit('SAVE_CAN_SIGN_USERS', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchUser({ commit }, userId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users/show/${userId}`)
        commit('SAVE_USER', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchEvaluatorsList({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users/get-evaluators-table`)
        commit('SAVE_EVALUATORS_LIST', resp.data.data)
        commit('SAVE_EVALUATORS_LIST_TOTAL', resp.data.total)
      } catch (e) {
        console.log(e)
      }
    },
    async filterEvaluatorsList({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/get-evaluators-table/filter`, data)
        commit('SAVE_EVALUATORS_LIST', resp.data.data)
        commit('SAVE_EVALUATORS_LIST_TOTAL', resp.data.total)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchEvaluationsList({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users/get-evaluations-table/${data.userId}/${data.action}`)
        commit('SAVE_EVALUATIONS_LIST', resp.data.data)
        commit('SAVE_EVALUATIONS_LIST_TOTAL', resp.data.total)
      } catch (e) {
        console.log(e)
      }
    },
    async filterEvaluationsList({ commit, state }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/get-evaluations-table/${state.dataEvaluationsView.userId}/${state.dataEvaluationsView.action}/filter`, filters)
        commit('SAVE_EVALUATIONS_LIST', resp.data.data)
        commit('SAVE_EVALUATIONS_LIST_TOTAL', resp.data.total)
      } catch (e) {
        console.log(e)
      }
    },
    async saveEvaluationsData({ commit }, data) {
      try {
        commit('SAVE_DATA_EVALUATIONS_VIEW', data)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchUserCVs({ commit }, userId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users/getCVs/${userId}`)
        commit('SAVE_CVS', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserGrants({ commit }, userId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-grants/${userId}`)
        commit('SAVE_GRANTS', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserSalaryHistories({ commit }, data) {
      try {
        let resp = {}
        if (data.perPage) {
          resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-salary-histories/${data.id}${data.page ? `?page=${data.page}` : ' '}`, { perPage: data.perPage })
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-salary-histories/${data.id}${data.page ? `?page=${data.page}` : ' '}`)
        }
        commit('SAVE_SALARY_HISTORIES', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserPromotionHistory({ commit }, data) {
      try {
        let resp = {}
        if (data.perPage) {
          resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-promotion-histories/${data.id}${data.page ? `?page=${data.page}` : ' '}`, { perPage: data.perPage })
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-promotion-histories/${data.id}${data.page ? `?page=${data.page}` : ' '}`)
        }
        commit('SAVE_PROMOTION_HISTORIES', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserSupplements({ commit }, data) {
      try {
        let resp = {}
        if (data.perPage) {
          resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-supplements/${data.id}${data.page ? `?page=${data.page}` : ' '}`, { perPage: data.perPage })
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-supplements/${data.id}${data.page ? `?page=${data.page}` : ' '}`)
        }
        commit('SAVE_SUPPLEMENTS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserUnpaidLeaves({ commit }, data) {
      try {
        let resp = {}
        if (data.perPage) {
          resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-unpaid-leaves/${data.id}${data.page ? `?page=${data.page}` : ' '}`, { perPage: data.perPage })
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-unpaid-leaves/${data.id}${data.page ? `?page=${data.page}` : ' '}`)
        }
        commit('SAVE_UNPAID_LEAVES', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserSabbaticals({ commit }, data) {
      try {
        let resp = {}
        if (data.perPage) {
          resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-sabbaticals/${data.id}${data.page ? `?page=${data.page}` : ' '}`, { perPage: data.perPage })
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-sabbaticals/${data.id}${data.page ? `?page=${data.page}` : ' '}`)
        }
        commit('SAVE_SABBATICALS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserDedications({ commit }, data) {
      try {
        let resp = {}
        if (data.perPage) {
          resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-dedications/${data.id}${data.page ? `?page=${data.page}` : ' '}`, { perPage: data.perPage })
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-dedications/${data.id}${data.page ? `?page=${data.page}` : ' '}`)
        }
        commit('SAVE_DEDICATIONS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserResidencies({ commit }, data) {
      try {
        let resp = {}
        if (data.perPage) {
          resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-residencies/${data.id}${data.page ? `?page=${data.page}` : ' '}`, { perPage: data.perPage })
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-residencies/${data.id}${data.page ? `?page=${data.page}` : ' '}`)
        }
        commit('SAVE_RESIDENCIES', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserOtherContracts({ commit }, data) {
      try {
        let resp = {}
        if (data.perPage) {
          resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-other-contracts/${data.id}${data.page ? `?page=${data.page}` : ' '}`, { perPage: data.perPage })
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-other-contracts/${data.id}${data.page ? `?page=${data.page}` : ' '}`)
        }
        commit('SAVE_OTHER_CONTRACTS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserOtherAgreements({ commit }, data) {
      try {
        let resp = {}
        if (data.perPage) {
          resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-other-agreements/${data.id}${data.page ? `?page=${data.page}` : ' '}`, { perPage: data.perPage })
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-other-agreements/${data.id}${data.page ? `?page=${data.page}` : ' '}`)
        }
        commit('SAVE_OTHER_AGREEMENTS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserIcreaContracts({ commit }, data) {
      try {
        let resp = {}
        if (data.perPage) {
          resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-icrea-contracts/${data.id}${data.page ? `?page=${data.page}` : ' '}`, { perPage: data.perPage })
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-icrea-contracts/${data.id}${data.page ? `?page=${data.page}` : ' '}`)
        }
        commit('SAVE_ICREA_CONTRACTS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserTemporaryLeaves({ commit }, data) {
      try {
        let resp = {}
        if (data.perPage) {
          resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-temporary-leaves/${data.id}${data.page ? `?page=${data.page}` : ' '}`, { perPage: data.perPage })
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-temporary-leaves/${data.id}${data.page ? `?page=${data.page}` : ' '}`)
        }
        commit('SAVE_TEMPORARY_LEAVES', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserHostHistories({ commit }, data) {
      try {
        let resp = {}
        if (data.perPage) {
          resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-host-histories/${data.id}${data.page ? `?page=${data.page}` : ' '}`, { perPage: data.perPage })
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-host-histories/${data.id}${data.page ? `?page=${data.page}` : ' '}`)
        }
        commit('SAVE_HOST_HISTORIES', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserEvaluatorHostHistories({ commit }, data) {
      try {
        let resp = {}
        if (data.perPage) {
          resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-evaluator-host-histories/${data.id}${data.page ? `?page=${data.page}` : ' '}`, { perPage: data.perPage })
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-evaluator-host-histories/${data.id}${data.page ? `?page=${data.page}` : ' '}`)
        }
        commit('SAVE_EVALUATOR_HOST_HISTORIES', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserSharedLabourHazards({ commit }, userId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-labour-hazard-shared/${userId}`)
        commit('SAVE_SHARED_LABOUR_HAZARDS', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserTraces({ commit }, userId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-traces/${userId}`)
        commit('SAVE_TRACES', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async clearTraces({ commit }) {
      commit('SAVE_TRACES', [])
    },
    async fetchUserScientificStats({ commit }, data) {
      try {
        // const queryParams = this.getQueryParams(data.yearBetween)
        let queryParams = ''
        if (Object.keys(data.yearBetween).length > 0) {
          if (data.yearBetween.from && data.yearBetween.to) {
            queryParams = `?between=${data.yearBetween.from} - ${data.yearBetween.to}`
          } else if (data.yearBetween.from) {
            queryParams = `?from=${data.yearBetween.from}`
          } else if (data.yearBetween.to) {
            queryParams = `?to=${data.yearBetween.to}`
          } else if (data.yearBetween.singleYear) {
            queryParams = `?singleYear=${data.yearBetween.singleYear}`
          } else if (data.yearBetween.calculateBetween) {
            queryParams = `?calculateBetween=${data.yearBetween.calculateBetween}`
          } else {
            queryParams = `?singleYear=${new Date().getFullYear()}`
          }
        } else {
          queryParams = `?singleYear=${new Date().getFullYear()}`
        }
        let resp = ''
        if (data.userId) {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-scientific-stats/${data.userId}${queryParams}`)
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-scientific-stats${queryParams}`)
        }
        commit('SAVE_SCIENTIFIC_STATS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserScientificPersonalInfo({ commit }, data) {
      try {
        // let queryParams = ''
        // if (Object.keys(data.yearBetween).length > 0) {
        //   if (data.yearBetween.from && data.yearBetween.to) {
        //     queryParams = `?between=${data.yearBetween.from} - ${data.yearBetween.to}`
        //   } else if (data.yearBetween.from) {
        //     queryParams = `?from=${data.yearBetween.from}`
        //   } else if (data.yearBetween.to) {
        //     queryParams = `?to=${data.yearBetween.to}`
        //   } else {
        //     queryParams = `?singleYear=${new Date().getFullYear()}`
        //   }
        // } else {
        //   queryParams = `?singleYear=${new Date().getFullYear()}`
        // }
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-scientific-personal-info/${data.userId}`)
        commit('SAVE_SCIENTIFIC_PERSONAL_INFO', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async uploadAvatar({}, data) {
      try {
        const formData = new FormData()
        formData.append('files', data.avatar.file)
        // formData.append('data', [data.avatar])

        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/set-avatar/${data.user_id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserScientificPublications({ commit }, data) {
      try {
        let queryParams = ''
        if (Object.keys(data.yearBetween).length > 0) {
          if (data.yearBetween.from && data.yearBetween.to) {
            queryParams = `?between=${data.yearBetween.from} - ${data.yearBetween.to}`
          } else if (data.yearBetween.from) {
            queryParams = `?from=${data.yearBetween.from}`
          } else if (data.yearBetween.to) {
            queryParams = `?to=${data.yearBetween.to}`
          } else {
            queryParams = `?singleYear=${new Date().getFullYear()}`
          }
        } else {
          queryParams = `?singleYear=${new Date().getFullYear()}`
        }
        if (data.fromPromotion) {
          const aux = 'fromPromotionList=true'
          queryParams = queryParams.concat('&', aux)
        }
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-scientific-publications/${data.userId}${queryParams}`)
        commit('SAVE_SCIENTIFIC_PUBLICATIONS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserScientificPublicationsFreezed({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-scientific-publications/${data.userId}`, { params: data })
        commit('SAVE_SCIENTIFIC_PUBLICATIONS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserScientificActivitiesFreezed({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-scientific-activity/${data.type}/${data.userId}`, { params: data })
        switch (data.type) {
          case 'conferences':
            commit('SAVE_SCIENTIFIC_CONFERENCES', resp.data)
            break
          case 'phds':
            commit('SAVE_SCIENTIFIC_PHDS', resp.data)
            break
          case 'courses':
            commit('SAVE_SCIENTIFIC_COURSES', resp.data)
            break
          case 'managerial':
            commit('SAVE_SCIENTIFIC_MANAGERIALS', resp.data)
            break
          case 'disseminations':
            commit('SAVE_SCIENTIFIC_DISSEMINATIONS', resp.data)
            break
          case 'stays-research':
            commit('SAVE_SCIENTIFIC_STAYS_RESEARCH', resp.data)
            break
          default:
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserScientificGrantsFreezed({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-scientific-grants/${data.userId}`, { params: data })
        commit('SAVE_SCIENTIFIC_GRANTS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserScientificGrants({ commit }, data) {
      try {
        let queryParams = ''
        if (Object.keys(data.yearBetween).length > 0) {
          if (data.yearBetween.from && data.yearBetween.to) {
            queryParams = `?between=${data.yearBetween.from} - ${data.yearBetween.to}`
          } else if (data.yearBetween.from) {
            queryParams = `?from=${data.yearBetween.from}`
          } else if (data.yearBetween.to) {
            queryParams = `?to=${data.yearBetween.to}`
          } else {
            queryParams = `?singleYear=${new Date().getFullYear()}`
          }
        } else {
          queryParams = `?singleYear=${new Date().getFullYear()}`
        }
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-scientific-grants/${data.userId}${queryParams}`)
        commit('SAVE_SCIENTIFIC_GRANTS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserScientificSabbaticals({ commit }, data) {
      try {
        let queryParams = ''
        if (Object.keys(data.yearBetween).length > 0) {
          if (data.yearBetween.from && data.yearBetween.to) {
            queryParams = `?between=${data.yearBetween.from} - ${data.yearBetween.to}`
          } else if (data.yearBetween.from) {
            queryParams = `?from=${data.yearBetween.from}`
          } else if (data.yearBetween.to) {
            queryParams = `?to=${data.yearBetween.to}`
          } else {
            queryParams = `?singleYear=${new Date().getFullYear()}`
          }
        } else {
          queryParams = `?singleYear=${new Date().getFullYear()}`
        }
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-scientific-sabbaticals/${data.userId}${queryParams}`)
        commit('SAVE_SCIENTIFIC_SABBATICALS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserScientificActivities({ commit }, data) {
      try {
        let queryParams = ''
        if (Object.keys(data.yearBetween).length > 0) {
          if (data.yearBetween.from && data.yearBetween.to) {
            queryParams = `?between=${data.yearBetween.from} - ${data.yearBetween.to}`
          } else if (data.yearBetween.from) {
            queryParams = `?from=${data.yearBetween.from}`
          } else if (data.yearBetween.to) {
            queryParams = `?to=${data.yearBetween.to}`
          } else {
            queryParams = `?singleYear=${new Date().getFullYear()}`
          }
        } else {
          queryParams = `?singleYear=${new Date().getFullYear()}`
        }
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-scientific-activity/${data.type}/${data.userId}${queryParams}`)
        switch (data.type) {
          case 'conferences':
            commit('SAVE_SCIENTIFIC_CONFERENCES', resp.data)
            break
          case 'phds':
            commit('SAVE_SCIENTIFIC_PHDS', resp.data)
            break
          case 'courses':
            commit('SAVE_SCIENTIFIC_COURSES', resp.data)
            break
          case 'managerial':
            commit('SAVE_SCIENTIFIC_MANAGERIALS', resp.data)
            break
          case 'disseminations':
            commit('SAVE_SCIENTIFIC_DISSEMINATIONS', resp.data)
            break
          case 'stays-research':
            commit('SAVE_SCIENTIFIC_STAYS_RESEARCH', resp.data)
            break
          default:
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchUserScientificPatents({ commit }, data) {
      try {
        let queryParams = ''
        if (Object.keys(data.yearBetween).length > 0) {
          if (data.yearBetween.from && data.yearBetween.to) {
            queryParams = `?between=${data.yearBetween.from} - ${data.yearBetween.to}`
          } else if (data.yearBetween.from) {
            queryParams = `?from=${data.yearBetween.from}`
          } else if (data.yearBetween.to) {
            queryParams = `?to=${data.yearBetween.to}`
          } else {
            queryParams = `?singleYear=${new Date().getFullYear()}`
          }
        } else {
          queryParams = `?singleYear=${new Date().getFullYear()}`
        }
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-scientific-patents/${data.userId}${queryParams}`)
        commit('SAVE_SCIENTIFIC_PATENTS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    getQueryParams(yearBetween) {
      let queryParams = ''
      if (Object.keys(yearBetween).length > 0) {
        if (yearBetween.from && yearBetween.to) {
          queryParams = `?between=${yearBetween.from} - ${yearBetween.to}`
        } else if (yearBetween.from) {
          queryParams = `?from=${yearBetween.from}`
        } else if (yearBetween.to) {
          queryParams = `?to=${yearBetween.to}`
        } else {
          queryParams = `?singleYear=${new Date().getFullYear()}`
        }
      } else {
        queryParams = `?singleYear=${new Date().getFullYear()}`
      }

      return queryParams
    },
    async fetchUserBasicData({ commit }, userId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-basic-data/${userId}`)
        commit('SAVE_BASIC_DATA', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async updateBasicData({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/users-basic-data/update/${data.userId}`, data.data)
        commit('SAVE_BASIC_DATA', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async fetchSendTrace({}, trace) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/sendTrace`, trace)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async uploadContract({}, contract) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/uploadContract`, contract)
        if (contract.contract) {
          Vue.prototype.$saveFiles(contract.contract.files)
        } else if (contract.otherAgreement) {
          Vue.prototype.$saveFiles(contract.otherAgreement.files)
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async updateContract({}, contract) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/updateContract`, contract)
        if (contract.contract) {
          Vue.prototype.$saveFiles(contract.contract.files)
        } else if (contract.otherAgreement) {
          Vue.prototype.$saveFiles(contract.otherAgreement.files)
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchModerators({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$identityUrl}/user/get-moderators`)
        commit('SAVE_MODERATORS', resp.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async fetchAspirants({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$identityUrl}/user`)
        commit('SAVE_ASPIRANTS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ASPIRANTS')
      }
    },
    async fetchFakeUsers({ commit, state }, name) {
      try {
        if (state.currentQuery) {
          state.currentQuery.cancel('Request canceled by user')
        }

        commit('SAVE_CURRENT_QUERY', axios.CancelToken.source())

        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$identityUrl}/user/filter`, { name }, { cancelToken: state.currentQuery.token })
        commit('SAVE_FAKE_USERS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ASPIRANTS')
      }
    },
    async fetchFakeUsersAux({ commit, state }, name) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$identityUrl}/user/filter`, { name }, { cancelToken: state.currentQuery.token })
        commit('SAVE_FAKE_USERS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ASPIRANTS')
      }
    },
    async fetchLastAccess({ commit, state }) {
      try {
        if (state.lastAccess.length === 0) {
          commit('SAVE_LAST_ACCESS', [true])
          const resp = await Vue.prototype.$http.get(`${Vue.prototype.$identityUrl}/user/access/last-access`)
          commit('SAVE_LAST_ACCESS', resp.data)
        }
      } catch (e) {
        commit('CLEAR_ASPIRANTS')
      }
    },
    async fetchChiefs({ commit }, name) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$identityUrl}/user/contacts`, name)
        commit('SAVE_CONTACTS', resp.data)
      } catch (e) {
        commit('SAVE_CONTACTS', [])
      }
    },
    async filterManagement({ commit, state }, filters) {
      try {
        if (state.currentQuery) {
          state.currentQuery.cancel('Request canceled by user')
        }

        commit('SAVE_CURRENT_QUERY', axios.CancelToken.source())

        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/filter-management`, { ...filters, min: true }, { cancelToken: state.currentQuery.token })
        commit('SAVE_USERS_TOTAL', resp.data.total)
        commit('SAVE_USERS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_USERS')
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/user`, data)
        commit('SAVE_USER', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/user/${data.id}`, data.data)
        commit('SAVE_USER', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async updateStatus({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/users/${data.id}/save-status`, { status: data.status })
        commit('SAVE_USER', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async sendUserCredentials({}, recipients) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/send-promo-credentials`, { recipients })
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async delete({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/user/${data.id}`, data.data)
        commit('SAVE_USER', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async deleteFile({}, fileId) {
      try {
        await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/files/delete/${fileId}`)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async deleteAdditionalEtcs({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-ercs/${data.id}`, { identification: data.identification })
        commit('SAVE_USER', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/users/export`,
          filters,
          name: 'users',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    cleanPeers({ commit }) {
      commit('SAVE_PEERS', [])
    },
    cleanUser({ commit }) {
      commit('CLEAR_USER')
    },
    async saveEvaluatorData({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/evaluators/save-legal-text`, data)
        commit('SAVE_USER', resp.data.data)
      } catch (e) {
        console.log(e)
        commit('CLEAR_USER')
      }
    },
    async checkEvaluatorData({ commit }) {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users/evaluators/has-legal-text`)
      commit('SAVE_HAS_LEGAL', resp.data === '')
    },
    async changeStatus({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/change-status`, data)
        commit('SAVE_USER', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async getPeers({ commit }, userId) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/${userId}/get-active-peers`)
        commit('SAVE_PEERS', resp.data.data)
        commit('SAVE_PEERS_TOTAL', resp.data.total)
      } catch (e) {
        console.log(e)
      }
    },
    async changePeerStatus({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/change-status-peers`, data)
      } catch (e) {
        commit('SAVE_PEERS', [])
      }
    },
    async filterPeers({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/null/get-active-peers`, data)
        commit('SAVE_PEERS', resp.data.data)
        commit('SAVE_PEERS_TOTAL', resp.data.total)
      } catch (e) {
        console.log(e)
      }
    },
    async setPeers({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/set-active-peers`, data)
        commit('SAVE_PEERS', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async proposePeer({ commit }, user) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/propose-peer`, user)
        commit('SAVE_PEERS', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async getEvaluatorHistory({ commit, state }, data) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users/get-evaluator-history/${data.user}${data.convo ? `?convo=${data.convo}` : ''}`)
        commit('SAVE_USER', { ...state.user, host_data: resp.data })
      } catch (e) {
        console.log(e)
      }
    },
    async fetch() {},
    async transferData({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/transfer-data/${data.id}`, { destinationId: data.destinationId })
      } catch (e) {
        console.log(e)
        throw e
      }
    },
  },
}
