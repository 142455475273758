import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    hazard: {},
    hazards: [],
    hazardsTotal: 0,
  },
  getters: {
    hazards: state => state.hazards,
    hazardsTotal: state => state.hazardsTotal,
    hazard: state => state.hazard,
  },
  mutations: {
    SAVE_HAZARDS(state, hazards) {
      state.hazards = hazards
    },
    SAVE_HAZARD(state, hazard) {
      state.hazard = hazard
    },
    SAVE_HAZARDS_TOTAL(state, hazardsTotal) {
      state.hazardsTotal = hazardsTotal
    },
    CLEAR_HAZARDS(state) {
      state.hazards = {}
    },
    CLEAR_HAZARD(state) {
      state.hazard = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/hazards`)
        commit('SAVE_HAZARDS_TOTAL', resp.data.total)
        commit('SAVE_HAZARDS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_HAZARDS')
      }
    },
    async fetchHazard({ commit }, hazardId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/hazards/${hazardId}`)
        commit('SAVE_HAZARD', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/hazards/${data.id}`, data.data)
        Vue.prototype.$saveFiles(data.data.files_new)
        commit('SAVE_HAZARD', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/hazards`, data)
        Vue.prototype.$saveFiles(data.files_new)
        commit('SAVE_HAZARD', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanHazard({ commit }) {
      commit('CLEAR_HAZARD')
    },
    async filter({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/hazards/filter`, data)
        commit('SAVE_HAZARDS_TOTAL', resp.data.total)
        commit('SAVE_HAZARDS', resp.data.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/hazards/export`,
          filters,
          name: 'ocupational_hazards',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
