import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    permissions: [],
  },
  getters: {
    permissions: state => state.permissions,
  },
  mutations: {
    SAVE_PERMISSIONS(state, permissions) {
      state.permissions = permissions
    },
    CLEAR_PERMISSIONS(state) {
      state.permissions = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/permission`)
        commit('SAVE_PERMISSIONS', resp.data)
      } catch (e) {
        commit('CLEAR_PPERMISSIONS')
      }
    },
    cleanPermissions({ commit }) {
      commit('CLEAR_PERMISSIONS')
    },
  },
}
