import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    items: [],
    itemsOffered: [],
    itemsAccepted: [],
    item: {
      public_data: {
        short_bio: '',
        research_interest: '',
      },
    },
    itemsTotal: 0,
    itemsTotalOffered: 0,
    itemsTotalAccepted: 0,
  },
  getters: {
    items: state => state.items,
    itemsOffered: state => state.itemsOffered,
    itemsAccepted: state => state.itemsAccepted,
    itemsTotal: state => state.itemsTotal,
    itemsTotalOffered: state => state.itemsTotalOffered,
    itemsTotalAccepted: state => state.itemsTotalAccepted,
    item: state => state.item,
  },
  mutations: {
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    SAVE_ITEMS_OFFERED(state, itemsOffered) {
      state.itemsOffered = itemsOffered
    },
    SAVE_ITEMS_ACCEPTED(state, itemsAccepted) {
      state.itemsAccepted = itemsAccepted
    },
    SAVE_ITEMS_OFFERED_TOTAL(state, itemsTotalOffered) {
      state.itemsTotalOffered = itemsTotalOffered
    },
    SAVE_ITEMS_ACCEPTED_TOTAL(state, itemsTotalAccepted) {
      state.itemsTotalAccepted = itemsTotalAccepted
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    CLEAR_ITEM(state) {
      state.items = {}
    },
    SAVE_STATUS(state, status) {
      state.status = status
    },
  },
  actions: {
    async fetchByUser({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/academy-awarded-user`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/academy-awarded`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async savePublicData({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/academy-awarded/save-public-data`, data)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEM')
      }
    },
    async fetchOffered({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/academy-awarded/type/offered`)
        commit('SAVE_ITEMS_OFFERED_TOTAL', resp.data.total)
        commit('SAVE_ITEMS_OFFERED', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchAccepted({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/academy-awarded/type/accepted`)
        commit('SAVE_ITEMS_ACCEPTED_TOTAL', resp.data.total)
        commit('SAVE_ITEMS_ACCEPTED', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async filter({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/academy-awarded/filter`, data)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async filterOffered({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/academy-awarded/type/offered/filter`, data)
        commit('SAVE_ITEMS_OFFERED_TOTAL', resp.data.total)
        commit('SAVE_ITEMS_OFFERED', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async filterAccepted({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/academy-awarded/type/accepted/filter`, data)
        commit('SAVE_ITEMS_ACCEPTED_TOTAL', resp.data.total)
        commit('SAVE_ITEMS_ACCEPTED', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchUser({ commit }, userId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/academy-awarded/${userId}`)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        commit('CLEAR_ITEM')
      }
    },
    async signed({ commit }, { documentId, data }) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/academy-awarded/document/${documentId}/signed`, data)
        Vue.swal('Guardat correctament', '', 'success')
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        commit('CLEAR_ITEM')
      }
    },
    async economyFile({ commit }, { userId, file }) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/academy-awarded/${userId}/economy-document`, file)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        commit('CLEAR_ITEM')
      }
    },
    async actions(n, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/academy-awarded/actions`, data)
        return resp.data
      } catch (e) {
        console.log(e)
        return null
      }
    },
    async saveEconomyJustified({ commit }, { userId, data }) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/academy-awarded/${userId}/update-amount`, data)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async dowloadZip({ commit }, id) {
      try {
        await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/academy-awarded/${id}/download-zip`, {
          responseType: 'blob',
        }).then(response => {
          console.log(response)
          if (response.data.type === 'application/json') {
            Vue.swal('Awarded haven\'t documents!', '', 'error')
            return
          }
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'academia-awardees.zip')
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
      } catch (e) {
        console.log(e)
      }
    },
    async rollbackJustification({ commit }, documentId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/academy-awarded/document/${documentId}/rollback-justification`)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
  },
}
