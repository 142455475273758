import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {},
    items: [],
    itemsTotal: 0,
    icreas: [],
    loading: true,
    institution: null,
    file: null,
    fileIcreas: [],
    alerts: [],
    alertsTotal: 0,
    icrea: [],
  },
  getters: {
    items: state => state.items,
    icreas: state => state.icreas,
    icrea: state => state.icrea,
    itemsTotal: state => state.itemsTotal,
    item: state => state.item,
    loading: state => state.loading,
    institution: state => state.institution,
    file: state => state.file,
    alerts: state => state.alerts,
    alertsTotal: state => state.alertsTotal,
    fileIcreas: state => state.fileIcreas,
  },
  mutations: {
    SAVE_FILE(state, file) {
      state.file = file
    },
    SAVE_INSTITUTION(state, institution) {
      state.institution = institution
    },
    TOGGLE_LOADING(state, loading) {
      state.loading = loading
    },
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ICREAS(state, icreas) {
      state.icreas = icreas
    },
    SAVE_ICREA(state, icrea) {
      state.icrea = icrea
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
      state.icreas = []
    },
    CLEAR_ITEM(state) {
      state.item = {
        active: true,
      }
    },
    SAVE_ALERTS(state, alerts) {
      state.alerts = alerts
    },
    SAVE_ALERTS_TOTAL(state, alerts) {
      state.alertsTotal = alerts
    },
    SAVE_FILE_ICREAS(state, fileIcreas) {
      state.fileIcreas = fileIcreas
    },
  },
  actions: {
    async acceptDocument({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/cae/accept-file`, data)
        commit('SAVE_FILE', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async uploadResponse({ commit }, data) {
      try {
        const formData = new FormData()
        formData.append('file', data.response)
        formData.append('file_id', data.file)
        formData.append('host_id', data.filters.host.id)
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/cae/upload-file-response`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/cae/view-file/${data.file}`, data.filters)
        commit('SAVE_FILE', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async deleteResponse({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/cae/delete-file-response/${data.id}`, data.filters)
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/cae/view-file/${data.file}`, data.filters)
        commit('SAVE_FILE', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchFile({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/cae/view-file/${data.id}`, data.filters)
        commit('SAVE_FILE', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    saveInstitution({ commit }, institution) {
      commit('SAVE_INSTITUTION', institution)
    },
    clear({ commit }) {
      commit('CLEAR_ITEMS')
    },
    async fetchIcreas({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/cae/icreas`, filters)
        commit('SAVE_ICREAS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchId({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/cae/view/${data.id}`, data.filters)
        commit('SAVE_ITEM', resp.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetch({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/cae/sections`, filters)
        commit('SAVE_ITEMS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchDocumentIcreas({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/cae/view-file/icreas/${data.document}`, data.filters)
        commit('SAVE_FILE_ICREAS', resp.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    toggleLoading({ commit }, loading) {
      commit('TOGGLE_LOADING', loading)
    },
    async globalFilter({ dispatch }, filters) {
      await dispatch('fetch', filters)
      await dispatch('fetchIcreas', filters)
    },
    async filterFiles({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/cae/files`, filters)
        commit('SAVE_ITEMS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchUser({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/cae/icrea/${id}`)
        commit('SAVE_ICREA', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchAlerts({ commit }) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/cae/alerts/filter`)
        commit('SAVE_ALERTS', resp.data.data)
        commit('SAVE_ALERTS_TOTAL', resp.data.total)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
  },
}
