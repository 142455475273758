import store from '../store'

export default function auth(to, from, next) {
  const ignoreLoginRoutes = ['front.peers', 'front.login', 'front.register', 'reset-password', 'reset-password-intranet', 'reset-password-sended-intranet', 'reset-password-generate', 'front.senior-call-upload-institution', 'front.senior-call-upload-parameter-file']
  if (!store.getters['auth/check']) {
    if (!ignoreLoginRoutes.includes(to.name)) {
      next({ name: 'front.login' })
    } else {
      next()
    }
  } else {
    next()
  }
}
