import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    alerts: [],
    alertsTotal: 0,
    pendings: false,
    loading: true,
  },
  getters: {
    alerts: state => state.alerts,
    alertsTotal: state => state.alertsTotal,
    pendings: state => state.pendings,
    loading: state => state.loading,
  },
  mutations: {
    SAVE_PENDING(state, pendings) {
      state.pendings = pendings
    },
    SAVE_ALERTS(state, alerts) {
      state.alerts = alerts.data.data
      state.alertsTotal = alerts.total
    },
    CLEAR_ALERTS(state) {
      state.alerts = []
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    MARK_TASK(state, task) {
      const tasks = state.alerts
      tasks[tasks.findIndex(x => x.id === task.id)].done = task.done
      state.alerts = tasks
    },
  },
  actions: {
    async fetchSent({ commit }, filters) {
      try {
        commit('SET_LOADING', true)
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$alertUrl}/alerts/filter`, { ...filters, sent: true })
        commit('SET_LOADING', false)
        commit('SAVE_ALERTS', resp.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async fetch({ commit }, filters) {
      try {
        commit('SET_LOADING', true)
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$alertUrl}/alerts/filter`, filters)
        commit('SET_LOADING', false)
        commit('SAVE_ALERTS', resp.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async fetchPendingToRead({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$alertUrl}/alerts/pendings`)
        commit('SAVE_PENDING', resp.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async create({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/alerts`, data)
        Vue.prototype.$saveFiles([data.file])
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async read({ commit }, id) {
      try {
        await Vue.prototype.$http.get(`${Vue.prototype.$alertUrl}/alerts/${id}/read`)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async mark({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$alertUrl}/alerts/${id}/mark`)
        commit('MARK_TASK', resp.data.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading)
    },
    newNotification({ commit }) {
      commit('SAVE_PENDING', true)
    },
    async store({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$alertUrl}/alerts`, data)
        commit('SAVE_ALERTS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
  },
}
